<template>
    <AtualizarGadget />
</template>

<script>
import AtualizarGadget from '@/components/Gadget/AtualizarGadget'
export default {
  components: {AtualizarGadget},
  data(){
    return{}
  }
}
</script>

<style>

</style>